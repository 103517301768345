/* body *{
  color: rgb(48, 48, 48) !important;
} */
*{
  font-family: "oswald" !important;
} 

.material-icons-round{
  font-family: 'Material Icons Round' !important;
}
.post-manage-btns {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  visibility: visible;
}

.post-card {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
}

.post-card * {
  color: rgb(48, 48, 48);
}
.pod-card * {
  color: rgb(48, 48, 48);
}

/* .post-card:hover {
  .post-manage-btns {
    visibility: visible;
    animation: fadeInAnimation ease 0.5s;
  }
} */

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgb(2, 188, 2);
  box-shadow: 0 0 0 0 #0004;
  animation: l1 1s infinite;
  margin: 7px 0 0 23px;
} */

@keyframes l1 {
  100% {
    box-shadow: 0 0 0 20px #0000;
  }
}

.model-footer {
  display: flex;
  gap: 10px;
}

.leave-pod-btn {
  margin: 0;
  padding: 5px;
  background: #fff;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  visibility: visible;
}

.join-pod-btn {
  margin: 0;
  padding: 5px;
  background: blue;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  visibility: visible;
}

/* .pod-card:hover {
  .leave-pod-btn {
    visibility: visible;
    animation: fadeInAnimation ease 0.5s;
  }
  .join-pod-btn {
    visibility: visible;
    animation: fadeInAnimation ease 0.5s;
  }
} */

@import url(https://fonts.googleapis.com/css?family=Roboto:300);
html {
  height: 90%;
}

.back {
  margin: 1em auto;
  font-family: "Roboto";
}
.back span {
  font-size: 2em;
  color: #fff;
  background: #262b37;
  display: table-cell;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 5px 0 #ccc;
  padding: 0 15px;
  border-radius: 10px;
  line-height: 80px;
  animation: jumb 2s infinite;
}
@keyframes jumb {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
    box-shadow: 0 15px 0 rgb(84, 207, 248);
  }
  100% {
    transform: translateY(0px);
  }
}
.back span:nth-child(1) {
  animation-delay: 0s;
}
.back span:nth-child(2) {
  animation-delay: 0.1s;
}
.back span:nth-child(3) {
  animation-delay: 0.2s;
}
.back span:nth-child(4) {
  animation-delay: 0.3s;
}
.back span:nth-child(5) {
  animation-delay: 0.4s;
}
.back span:nth-child(6) {
  animation-delay: 0.5s;
}
.back span:nth-child(7) {
  animation-delay: 0.6s;
}

.global-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: #f0f2f5;
}

.select-pod {
  border: 2px solid blue !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div.pod-description {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.post-title {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 25px;
  font-weight: 400;
  text-transform: capitalize;
}

.pod-name{
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 25px;
  font-weight: 400;
  text-transform: capitalize;
}
.loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #1a73e8;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #1a73e8;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.css-p6upx4-MuiFormHelperText-root {
  color: red !important;
}

.css-uk4jy3-MuiFormLabel-root-MuiInputLabel-root, .css-1hiu5rg, .css-5rxz7w-MuiFormLabel-root-MuiInputLabel-root, .css-fdqox3 {
  z-index: 0 !important;
}
